import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import AdvertisementBanner from '../components/Advertisement/AdvertisementBanner';
import CollectionConfig from 'config/collectionBanner.json';
import { usePathname } from 'next/navigation';

const ContentGrid = ({
  h1Text,
  children,
  className,
  sidebar,
  mainClassName = 'story-content',
  adInsert,
}) => {
  return (
    <div className={className}>
      <Head>
        <link href="/css/pages/story.css" rel="stylesheet" />
      </Head>
      {`/${CollectionConfig.slug}` === usePathname() ? (
        <div className="hdg-section collection-banner">
          <div className="collection-banner-first-area">
            <h1 className="collection-banner-logo">
              <img
                src={`${CollectionConfig.logo}`}
                alt={CollectionConfig.name || h1Text}
              />
            </h1>
            <div className="collection-banner-images">
              {CollectionConfig.images.map((img, i) => {
                return (
                  <div key={i}>
                    <img src={img.path} alt={img.alt} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        h1Text && (
          <div className="hdg-section">
            <div className="container hdg-section-text">
              <h1>{h1Text}</h1>
              <div className="premium-underwriting">
                <AdvertisementBanner
                  id="premium_ad"
                  adUnitPath="ad/path/goes/here"
                  sizes={[[320, 25]]}
                  sizeMap={{ 0: [320, 25] }}
                  customCss="underwriting"
                />
              </div>
            </div>
          </div>
        )
      )}

      <div className="container grid grid-4col grid-3-1">
        <main className={`grid-primary ${mainClassName}`}>
          {children}
          {adInsert && (
            <AdvertisementBanner
              id={adInsert.id}
              sizes={adInsert.sizes}
              sizeMap={adInsert.sizeMap}
              cmsId={adInsert.cmsId}
              primaryCollection={adInsert.primaryCollection}
              collections={adInsert.collections}
              position={adInsert.position}
            />
          )}
        </main>
        {sidebar && <div className="story-sidebar">{sidebar}</div>}
      </div>
    </div>
  );
};

ContentGrid.propTypes = {
  h1Text: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  sidebar: PropTypes.node,
  mainClassName: PropTypes.string,
  adInsert: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    sizes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    sizeMap: PropTypes.shape({
      0: PropTypes.arrayOf(PropTypes.number),
    }),
    cmsId: PropTypes.string,
    primaryCollection: PropTypes.string,
    collections: PropTypes.arrayOf(PropTypes.string),
    position: PropTypes.string,
  }),
};

export default ContentGrid;
